.Container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    color: white;
    position: fixed;
    bottom: 45px;
    right: 35px;
    width: 80px;
    height: 150px;
    resize: both;
    z-index: 70;
    border-radius: 50px;
    animation: fadeIn 2.3s ease-out;
}

.Text p {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #2E86C1;
    opacity: 0.9;
    width: 80px;
    border-radius: 50px;
    margin-left: 100px;
    margin-bottom: 0px;
}

.Telegram {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #2E86C1;
    opacity: 0.9;
    width: 50px;
    height: 50px;
    resize: both;
    box-shadow: 1px 1px 1px 1px #ccc;
    border-radius: 50px;
    margin-left: 70px;
    margin-top: 5px;
}

.Telegram:hover {
    opacity: 1;
}

.WA img {
    height: 100%;
    width: 100%;
    border-radius: 20px;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@media (min-width: 500px) {
    .Container {
        visibility: hidden;
    }
}