.ParticipantItem {
    display: block;
    flex-direction: column;
    width: 94vw;
    margin-left: 3vw;
    margin-right: 3vw;
    background-color:#ffffed;
    border-radius: 20px;
    /* border-width: 3px;
    border-style: dotted; */
    padding-top: 5px;
    padding-bottom: 5px;
    margin-bottom: 10px;
}

.ParticipantItem p {
    font-size: 13px;
    margin-top: 0px;
    margin-bottom: 0px;
}

@media (min-width: 900px) {
    .ParticipantItem p {
        font-size: 15px;
    }
}