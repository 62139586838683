.Container {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.Container img {
    max-height: 400px;
    max-width: 100%;
    object-fit: contain;
    margin-top: 30px;
}

.CloseButton {
    position: absolute;
    right: 10px;
    top: 10px;
}

/* .Description {
    padding: 0px 20px;
    height: 100%;
    justify-content: center;
} */

@media (min-width: 500px) {
    .Container img {
        width: 100%;
    }
    /* .Description {
        display: flex;
        flex-direction: column;
        padding: 0px 50px;
    } */
}