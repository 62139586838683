.Image {
    width: 80vw;
    margin-top: 20px;
    margin-bottom: 40px;
    margin-left: 10vw;
    margin-right: 10vw;
    border-radius: 20px;
}

@media (min-width: 900px) {
    .Image {
        width: 40vw;
        margin-left: 30vw;
        margin-right: 30vw;
    }
}