.Centered {
    display: flex;
    position: absolute;
    top: 30%;
    right: 45%;
}

.PostPage {
    display: flex;
    flex-direction: column;
    width: 100vw;
    background-color:  #25232b;
    padding-bottom: 60px;
}

.PostPage_Header {
    display: flex;
    flex-direction: column;
    margin-top: 40px;
    margin-bottom: 40px;
}

.PostPage_Header h1{
    font-size: 38px;
    color: #ffc30b;
    text-align: left;
    margin-left: 10vw;
    margin-right: 10vw;
    margin-bottom: 10px;
}
.PostPage_Header p {
    font-size: 16px;
    color: #7ef9ff;
    text-align: left;
    margin-left: 10vw;
    margin-right: 10vw;
}

.PostPage_Body h1 {
    font-size: 24px;
    color: #fff;
    text-align: justify;
    margin-left: 10vw;
    margin-right: 10vw;
    margin-bottom: 30px;
    line-height: 35px;
}

.PostPage_Body h2 {
    font-size: 20px;
    color: #fff;
    text-align: justify;
    margin-left: 10vw;
    margin-right: 10vw;
    margin-bottom: 30px;
    line-height: 35px;
}

@media (min-width: 500px) {
    .PostPage {
        padding-bottom: 20px;
    }
    .PostPage_Header h1{
        font-size: 50px;
    }
}


