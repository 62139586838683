.NavigationItem {
    margin: 15px 0;
    box-sizing: border-box;
    display: block;
    width: 100%;
}

.NavigationItem a {
    color: #25232b;
    text-decoration: none;
    width: 100%;
    box-sizing: border-box;
    display: block;
}

.NavigationItem a:hover, .NavigationItem a:active, .NavigationItem a.active {
    color: steelblue;
}

@media (min-width: 900px) {
    .NavigationItem {
        margin: 10px;
        display: flex;
        height: 100%;
        width: auto;
        align-items: center;
    }
    .NavigationItem a {
        color: #25232b;
        height: 100%;
        padding: 16px 10px;
        border-bottom: 4px solid transparent;
    }
    .NavigationItem a:hover, .NavigationItem a:active, .NavigationItem a.active {
        background-color: #25232b;
        border-bottom: 4px solid gray;
        color: white;
    }
}