.Centered {
    display: flex;
    position: absolute;
    top: 30%;
    right: 45%;
}

.StockCourseLandingPage {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100vw;
    padding-bottom: 60px;
}

.StockCourseLandingPage ul {
    color: #25232b;
    margin-left: 10vw;
}

.StockCourseLandingPage_Header h1, h2, h3, h4, h5, p {
    color: #25232b;
    margin-right: 10vw;
    margin-left: 10vw;
}

.StockCourseLandingPage_Header {
    display: flex;
    flex-direction: column;
    width: 100vw;
    padding-top: 20px;
    padding-bottom: 30px;
    background-color: #111;
}

.StockCourseLandingPage_Header_Hello {
    animation: moveInRightStop 1s ease-out;
}

.StockCourseLandingPage_Header_Explanation {
    animation: fadeIn 2s ease-in;
}

.StockCourseLandingPage_Intro h1, h2, h4, h5, p {
    color: #25232b;
    margin-right: 10vw;
    margin-left: 10vw;
}

.StockCourseLandingPage_Intro h3 {
    color: #25232b;
    margin-right: 10vw;
    margin-left: 10vw;
    margin-bottom: 0px;
}

.StockCourseLandingPage_Intro ul {
    color: #25232b;
    margin-left: 10vw;
    margin-right: 10vw;
    margin-bottom: 5px;
    margin-top: 5px;
}

.StockCourseLandingPage_Intro {
    display: flex;
    flex-direction: column;
    width: 100vw;
    padding-top: 30px;
    padding-bottom: 60px;
}

.StockCourseLandingPage_Body {
    display: flex;
    align-items: flex-start;
    width: 100vw;
    padding-bottom: 50px;
    background-color: #111;
}

.StockCourseLandingPage_Body_Posts h1, h2, p {
    margin-right: 10vw;
    margin-left: 10vw;
}

.StockCourseLandingPage_Body_Posts h1 {
    color: #fff;
    margin-bottom: 30px;
    margin-top: 30px;
}

@media (min-width: 500px) {
    .StockCourseLandingPage {
        padding-bottom: 20px;
    }
}

@media (min-width: 900px) {
    .StockCourseLandingPage_Header {
        padding-top: 50px;
        padding-bottom: 60px;
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes moveInRight {
    0% {
        opacity: 0;
        transform: translateX(-100px);
    }
    80% {
        transform: translateX(10px);
    }
    100% {
        opacity: 1;
        transform: translate(0);
    }
}

@keyframes moveInRightStop {
    0% {
        opacity: 0;
        transform: translateX(-100px);
    }
    100% {
        opacity: 1;
        transform: translate(0);
    }
}

@keyframes moveInLeft {
    0% {
        opacity: 0;
        transform: translateX(100px);
    }
    80% {
        transform: translateX(-10px);
    }
    100% {
        opacity: 1;
        transform: translate(0);
    }
}

@keyframes moveInLeftStop {
    0% {
        opacity: 0;
        transform: translateX(100px);
    }
    100% {
        opacity: 1;
        transform: translate(0);
    }
}