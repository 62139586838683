.Layout_Footer {
    display: flex;
    flex-direction: column;
    width: 100vw;
    position: fixed;
    bottom: 0px;
    height: 60px;
    background-color: whitesmoke;
}

.Layout_Footer_Navigator {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    width: 100vw;
    height: 50px;
}

.Layout_Footer_Copyright {
    display: flex;
    width: 100vw;
    height: 20;
}

.Layout_Footer_Copyright p {
    font-size: 12px;
    color: #292f33;
    margin: 0px;
    width: 100vw;
    text-align: center;
}

@media (min-width: 500px) {
    .Layout_Footer {
        height: 20px;
    }
    .Layout_Footer_Navigator {
        height: 0px;
        opacity: 0;
    }
    .Layout_Footer_Copyright {
        height: 20px;
    }
}