.WA {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(131, 197, 32);
    color: white;
    opacity: 0.7;
    position: fixed;
    bottom: 80px;
    right: 20px;
    width: 50px;
    height: 50px;
    resize: both;
    z-index: 180;
    box-shadow: 1px 1px 1px 1px #ccc;
    border-radius: 50px;
    animation: fadeIn 2.3s ease-out;
}

.WA:hover {
    opacity: 1;
}

.WA img {
    height: 100%;
    width: 100%;
    border-radius: 20px;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    50% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}