.ArticleItem {
    display: block;
    flex-direction: column;
    width: 94%;
    margin-left: 3%;
    max-width: 600px;
    background-color: #ffffed;
    border-radius: 20px;
    /* border-width: 3px;
    border-style: dotted; */
    padding-top: 5px;
    padding-bottom: 5px;
    margin-bottom: 10px;
}

.ArticleItem p {
    font-size: 13px;
}

@media (min-width: 900px) {
    .ArticleItem p {
        font-size: 15px;
    }
}