.Button {
    display: flex;
    flex-direction: column;
    opacity: 0.8;
    position: fixed;
    bottom: 70px;
    right: 10px;
    width: 50px;
    height: 50px;
    resize: both;
    z-index: 70;
    box-shadow: 1px 1px 1px 1px #ccc;
    border-radius: 200px;
    animation: fadeIn 12s ease-out;
    align-items: center;
    justify-content: center;
    opacity: 0;
}

.Button p {
    font-weight: bold;
    font-size: 10px;
    margin-bottom: 0px;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    20% {
        opacity: 1;
    }

    100% {
        opacity: 1;
    }
}

@media (min-width: 500px) {
    .Button {
        width: 60px;
        height: 60px;
        right: 30px;
        bottom: 30px;
        opacity: 1;
    }
}