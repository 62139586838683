.Centered {
    display: flex;
    position: absolute;
    top: 30%;
    right: 45%;
}

.AccountPage {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100vw;
    padding-bottom: 60px;
}

.AccountPage_Menu {
    height: 60px;
    width: 80vw;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 0.1px solid #ccc;
}

.AccountPage_MenuItems {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: space-evenly;
    height: 100%;
}

.AccountPage_MenuItem {
    margin: 10px;
    display: flex;
    height: 100%;
    width: auto;
    align-items: center;
}

.AccountPage_MenuItem p {
    cursor: pointer;
    color: #25232b;
    text-decoration: none;
    width: 100%;
    box-sizing: border-box;
    display: block;
}

.AccountPage_MenuItem p:hover, .AccountPage_MenuItem p:active, .AccountPage_MenuItem p.active {
    /* background-color: #25232b; */
    border-bottom: 4px solid orange;
    font-weight: bold;
    /* color: white; */
}



.AccountPage_SettingsBox {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100vw;
    padding-top: 30px;
}

.AccountPage_AffiliateBox {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100vw;
    padding-top: 30px;
}

.AccountPage_ListItem {
    display: flex;
    flex-direction: row;
    height: 100%;
}
