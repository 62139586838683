.CourseItem {
    display: flex;
    box-sizing: border-box;
    background-color: whitesmoke;
    width: 100%;
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 8px;
    border-width: 1px;
    border-color: black;
    margin-bottom: 5px;
    cursor: pointer;
}

.CourseItem_Number {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    font-weight: bolder;
    padding: 0%;
    width: 15%;
}

.CourseItem_Title {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 14px;
    width: 65%;
}

.CourseItem_Checklist {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    padding: 0%;
    width: 20%;
}
