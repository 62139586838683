.Container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    color: white;
    position: fixed;
    bottom: 45px;
    width: 100vw;
    left: 8vw;
    z-index: 90;
    border-radius: 50px;
    animation: fadeIn 2.3s ease-out;
}

.Text p {
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #05828e;
    opacity: 0.9;
    border-radius: 50px;
    margin-left: 100px;
    margin-bottom: 0px;
}

.Icon {
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0.9;
    width: 25px;
    height: 25px;
    resize: both;
    border-radius: 50px;
    margin-left: 70px;
    margin-top: 0px;
}

.Icon:hover {
    opacity: 1;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@media (min-width: 500px) {
    .Container {
        visibility: hidden;
    }
}